import React from "react";

import { Widget } from "@typeform/embed-react";

import Layout from "../components/theme/layout";
import Seo from "../components/seo";

export default function FeatureRequestPage() {

    return (
        <Layout>
            <Seo title="Advanced Editor Feedback" />

            <div>
                <Widget id='qejUnr4R' height={500} />
            </div>
        </Layout>
    )
}